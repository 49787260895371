<template>
  <div class="license-container">
    <div class="license-title">
      {{ title }}
    </div>
    <div class="license-header">
      {{ header }}
    </div>
    <div class="license-description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "License",

  props: {
    title: {
      type: String,
      required: true,
    },

    header: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.license-container {
  padding: 16px;
}

.license-title {
  font-size: 14px;
  color: var(--text-color-primary);
}

.license-header {
  font-size: 13px;
  margin-top: 8px;
  white-space: pre-line;
  color: var(--text-color-primary);
}

.license-description {
  font-size: 12px;
  margin-top: 8px;
  white-space: pre-line;
  color: var(--text-color-secondary);
}
</style>
